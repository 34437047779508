import * as React from "react"

import Scrollspy from 'react-scrollspy';

import Layout from "../components/layout";
import LatestGrid from "../components/image-layouts/latestGrid";
import {graphql} from "gatsby";


const IndexPage = ({data}) => {

    const categories = [
        'street',
        'urban',
        // 'grayscale',
        // 'pets',
        'misc',
    ]

    const scrollSpyMenuItems = categories.map((cat, index) => {
        return <li key={index+1} className={'text-4xl opacity-25 hover:opacity-100 transition-all'}><a href={`#section-${cat.toLowerCase().trim()}`} className={'block py-1'}>{cat.charAt(0).toUpperCase() + cat.slice(1)}</a></li>
    })

    const scrollSpySectionItems = categories.map((cat, index) => {
        const sanitizedCat = cat ? cat.toLowerCase().trim() : 'latest';
        return <LatestGrid nodes={data[sanitizedCat].nodes} id={`section-${cat}`} to={cat} className={index > 0 ? 'mt-60' : ''} />
    })

    return (
        <Layout>
            <nav className={'fixed bottom-6 left-6'}>
                <Scrollspy items={categories.map(cat => `section-${cat}`)} className={'flex flex-col'} currentClassName={'opacity-100'}>
                    {scrollSpyMenuItems}
                </Scrollspy>
            </nav>

            {scrollSpySectionItems}

        </Layout>
    )
}

export default IndexPage

export const query = graphql`
    query {
        latest: allFile(
            filter: {
                extension: {regex: "/(jpg)|(jpeg)/"},
            }
            limit: 12
            sort: {order: DESC, fields: modifiedTime}
        ) {
            nodes {
                childImageSharp {
                    gatsbyImageData(width: 400, placeholder: BLURRED)
                    fluid {
                        originalName
                    }
                }
                relativeDirectory
            }
        }
    
        street: allFile(
            filter: {
                extension: {regex: "/(png)|(jpg)|(jpeg)/"},
                relativeDirectory: {eq: "photography/street"}
            }
            limit: 12
            sort: {order: DESC, fields: modifiedTime}
        ) {
            nodes {
                childImageSharp {
                    gatsbyImageData(width: 400, placeholder: BLURRED)
                    fluid {
                        originalName
                    }
                }
                relativeDirectory
            }
        }
        
        urban: allFile(
            filter: {
                extension: {regex: "/(png)|(jpg)|(jpeg)/"},
                relativeDirectory: {eq: "photography/urban"}
            }
            limit: 12
            sort: {order: DESC, fields: modifiedTime}
        ) {
            nodes {
                childImageSharp {
                    gatsbyImageData(width: 400, placeholder: BLURRED)
                    fluid {
                        originalName
                    }
                }
                relativeDirectory
            }
        }
        
        misc: allFile(
            filter: {
                extension: {regex: "/(png)|(jpg)|(jpeg)/"},
                relativeDirectory: {eq: "photography/misc"}
            }
            limit: 12
            sort: {order: DESC, fields: modifiedTime}
        ) {
            nodes {
                childImageSharp {
                    gatsbyImageData(width: 400, placeholder: BLURRED)
                    fluid {
                        originalName
                    }
                }
                relativeDirectory
            }
        }
    }
`

export const Head = () => <title>Rol John Torralba</title>
